import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { locationsTab } from "../data/mock-data";
import { useRouter } from "next/router";

const OptionsTab = () => {
  const [value, setValue] = useState<number>(0);
  const router = useRouter();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
    const { query } = router;

    query["category"] = newValue.toString();
    query["destination_id"] = newValue.toString();

    router.push({ pathname: `/search`, query: query });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexGrow: 1,
          px: { xs: 0, md: 2 },
          alignItems: "center",
          mb: 3,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {locationsTab.map((tab) => {
            return (
              // <Link href={`search?category=${tab.id}`} passHref>
              <Tab
                sx={{color: 'black', opacity: 0.8, fontSize: 13}}
                key={tab.id}
                icon={tab.icon}
                label={tab.label}
                value={tab.id}
              />
              // </Link>
            );
          })}
        </Tabs>
        {/*<ModalDialog />*/}
      </Box>
    </>
  );
};

export default OptionsTab;
