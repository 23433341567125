import React from 'react';
import Image from 'next/image';

export const locationsTab = [
  {
    id: "TAB_5635",
    label: "Tropical",
    icon: (
        <Image
            src="/icons/FaUmbrellaBeach.svg"
            width="24"
            height="24"
            loading="lazy"
        />
    ),
  },
  {
    id: "TAB_4104",
    label: "CountrySide",
    icon: (
        <Image
            src="/icons/GiMountainCave.svg"
            width="24"
            height="24"
            loading="lazy"
        />
    ),
  },
  {
    id: "TAB_8225",
    label: "OMG!",
    icon: (
        <Image
            src="/icons/GiSpaceNeedle.svg"
            width="24"
            height="24"
            loading="lazy"
        />
    ),
  },
  {
    id: "TAB_5348",
    label: "Cabins",
    icon: (
        <Image src="/icons/MdCabin.svg" width="24" height="24" loading="lazy" />
    ),
  },
  {
    id: "TAB_8534",
    label: "Arctic",
    icon: (
        <Image src="/icons/BsSnow.svg" width="24" height="24" loading="lazy" />
    ),
  },
  {
    id: "TAB_8528",
    label: "Design",
    icon: (
      <Image
        src="/icons/MdOutlineApartment.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },

  {
    id: "TAB_8542",
    label: "Shared Homes",
    icon: (
      <Image
        src="/icons/MdHouseSiding.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8522",
    label: "LakeFront",
    icon: (
      <Image
        src="/icons/MdOutlineWater.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8102",
    label: "National Parks",
    icon: (
      <Image
        src="/icons/GiKidSlide.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8538",
    label: "Bed & Breakfast ",
    icon: (
      <Image
        src="/icons/AiOutlineCoffee.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },

  {
    id: "TAB_634",
    label: "Camping",
    icon: (
      <Image
        src="/icons/FaCampground.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8148",
    label: "A-frames",
    icon: (
      <Image
        src="/icons/GiCampingTent.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8173",
    label: "Domes",
    icon: (
      <Image
        src="/icons/GiLightningDome.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8186",
    label: "Tiny Homes",
    icon: (
      <Image src="/icons/BiHomeAlt.svg" width="24" height="24" loading="lazy" />
    ),
  },
  {
    id: "TAB_8188",
    label: "Treehouses",
    icon: (
      <Image
        src="/icons/GiEvilTree.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8526",
    label: "Surfing",
    icon: (
      <Image
        src="/icons/GiWaveSurfer.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },

  {
    id: "TAB_670",
    label: "Caves",
    icon: (
      <Image
        src="/icons/GiCaveEntrance.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
  {
    id: "TAB_8525",
    label: "Golfing",
    icon: (
      <Image
        src="/icons/GiGolfFlag.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },

  {
    id: "TAB_8174",
    label: "Earth Homes",
    icon: (
      <Image
        src="/icons/RiEarthquakeFill.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },

  {
    id: "TAB_677",
    label: "Amazing Pools",
    icon: (
      <Image
        src="/icons/FaSwimmingPool.svg"
        width="24"
        height="24"
        loading="lazy"
      />
    ),
  },
];
